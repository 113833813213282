// =======================================
//     List of variables for layout
// =======================================

$header-height: 70px;
$sidebar-width: 255px;
$sidebar-collapsed-width: 70px;

// header
$header-background: #fff;
$header-color: #525b69;
$header-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
$brand-color: #161c25;
$header-item-border-color: #CECECE33;

// Menu
$sidebar-background: #292A33;
$sidebar-color: #fff;
$sidebar-icon-color: #778290;
$sidebar-main-active-color: #fff;
$sidebar-active-color: $primary;
$sidebar-caption: $header-color;
$sidebar-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);

// horizontal menu
$topbar-height: 60px;
$topbar-background: #1c232f;
$topbar-color: #b5bdca;
$header-submenu-background: #fff;
$header-submenu-color: #1c232f;


// card block
$card-shadow:  none;

$soft-bg-level: -80%;

$themes-colors: (
    theme-1: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary:#51459D,
        primary-grd: linear-gradient(141.55deg, rgba(81, 69, 157, 0) 3.46%, rgba(255, 58, 110, 0.6) 99.86%),
    ),
    theme-2: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #1F3996,
        primary-grd: linear-gradient(141.55deg, rgba(81, 69, 157, 0) 3.46%, #4EBBD3 99.86%),
    ),
    theme-3: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #6fd943,
        primary-grd: linear-gradient(141.55deg, #6fd943 3.46%, #6fd943 99.86%),
    ),
    theme-4: (
        body: linear-gradient(141.55deg, #22242C 3.46%, #22242C 99.86%),
        primary: #584ED2,
        primary-grd: linear-gradient(141.55deg, rgba(104, 94, 229, 0) 3.46%, #685EE5 99.86%),
    ),
);